import React, { Fragment, useState, useEffect, useRef, useCallback } from 'react';
import { usePubNub } from 'pubnub-react';
import {
  ChannelList,
  Chat,
  MemberList,
  MessageInput,
  MessageList,
  TypingIndicator,
  useChannelMembers,
  usePresence,
  useUser,
  useUserMemberships,
  useUsers,
} from '@pubnub/react-chat-components';
import axios from 'axios';
import { chunk, orderBy } from 'lodash';
import { getUnixTime } from 'date-fns'

import { channelRender, messageRenderer } from './renderer';

import './Chat.scss';

import icnSearch from './icn-search.svg';
import icnArrowRight from './icn-arrow-right.svg';
import icnClose from './icn-close.svg';

export default function ChatWrapper({ setToken, parsedToken, is_supervisor }) {
  const defaultChannel = { id: 'default' };

  const timeOut = useRef(null);

  const [channelTimetokens, setChannelTimetokens] = useState({ next: null, total: 0, data: [] });
  const [unreadChannelMessages, setUnreadChannelMessage] = useState({});
  const [currentChannel, setCurrentChannel] = useState(defaultChannel);
  const [showMembers, setShowMembers] = useState(false);
  const [showChannels, setShowChannels] = useState(true);
  const [channelsFilter, setChannelsFilter] = useState('');
  const [membersFilter, setMembersFilter] = useState('');

  const pubnub = usePubNub();
  const uuid = pubnub.getUUID();
  const [currentUser] = useUser({ uuid });

  const [joinedChannels, fetchMoreChannels, ,totalChannels] = useUserMemberships({
    include: { channelFields: true, customChannelFields: true }
  });

  const [channelMembers] = useChannelMembers({
    channel: currentChannel.id,
    include: { customUUIDFields: true },
  });

  const groupChannels = joinedChannels
  .filter(
    (c) =>
      c.id?.startsWith('space.') && c.name?.toLowerCase().includes(channelsFilter.toLowerCase())
  );

  let setMessageRead = (channel) => {
    pubnub.objects.setMemberships({
      channels: [{
        id: channel.id,
        custom: {
          lastReadTimetoken: Date.now() * 10000 + 1200000 // Add 1 minute (60000 milliseconds)
        }
      }]
    })
  }

  let setMessageUnread = (channel) => {
    pubnub.objects.setMemberships({
      channels: [{
        id: channel.id,
        custom: {
          lastReadTimetoken: null
        }
      }]
    })
    setUnreadChannelMessage(prevState => ({ ...prevState, [channel.id]: 1 }));
  }

  const setChannel = useCallback((channel) => {

    setCurrentChannel(channel);

    clearTimeout(timeOut.current);

    timeOut.current = setTimeout(() => {
      setUnreadChannelMessage(prevState => ({ ...prevState, [channel.id]: 0 }));
      setMessageRead(channel);
    }, 2500);

  })

  const getMemberships = useCallback((nextPage) => {

    const options = {
      include: {
        totalCount: true,
        customFields: true
      }
    }

    if (nextPage) { options['page'] = { next: nextPage } }

    return pubnub.objects.getMemberships(options)

  }, []);

  useEffect(() => {
    if (currentChannel.id === 'default' && joinedChannels.length)
      setChannel(joinedChannels[0]);
  }, [currentChannel, joinedChannels]);

  // Paginazione sui canali dell'utente
  //
  useEffect(() => {
    if (joinedChannels.length < totalChannels) {
      fetchMoreChannels();
    }
  }, [joinedChannels]);

  // Recupero la data di ultima lettura del canale
  //
  const getMembershipsCallback = useCallback(({ data, totalCount, next }) => {
    const tokens = data.map(d => {
      const t = (d.custom && d.custom.lastReadTimetoken) || getUnixTime(new Date(2022,1,1)) * 10000
      return { channel: d.channel.id, timetoken: String(t) }
    });
    setChannelTimetokens(prevState => ({ total: totalCount, next, data: [...prevState.data, ...tokens] }));
  }, [setChannelTimetokens]);

  useEffect(() => {
    if (channelTimetokens.next === null || (channelTimetokens.data.length < channelTimetokens.total)) {
      getMemberships(channelTimetokens.next).then(getMembershipsCallback);
    }
  }, [channelTimetokens])
  ///

  // Recupero le notifiche non lette per l'utente in base all'ultima data di lettura
  //
  useEffect(() => {

    if (channelTimetokens.data.length == 0 || channelTimetokens.data.length !== channelTimetokens.total) { return }

    chunk(channelTimetokens.data, 100).forEach(c => {

      pubnub.messageCounts({
        channels: c.map(t => t.channel),
        channelTimetokens: c.map(t => t.timetoken),
      }).then((response) => {
        setUnreadChannelMessage(prevState => ({...prevState, ...response.channels}));
        }).catch((error) => {
          console.log("Errore: ", error)
        }
      );

    })

  }, [channelTimetokens, setUnreadChannelMessage])

  const handleError = (e) => {
    if (
      (e.status?.operation === 'PNPublishOperation' && e.status?.statusCode === 403) ||
      e.message.startsWith('Publish failed')
    ) {
      alert('Si è verificato un errore.');

      // fetch new token
      axios.get('/conversations/token').then((res) => {
        setToken(res.data.token);
      });
    }
  };

  const deleteMessage = (timetoken) => {

    pubnub.deleteMessages(
      {
        channel: currentChannel.id,
        start: (BigInt(timetoken) - BigInt(1)).toString(),
        end: timetoken,
      },
      function (status, response) {
        console.log(status, response);
      }
    );

  }

  return (
    <div className="chat-app">
      <Chat
        theme="light"
        users={channelMembers}
        currentChannel={currentChannel.id}
        channels={[...joinedChannels.map((c) => c.id), uuid]}
        onError={handleError}
      >
        <Fragment>
          <div className={`channels-panel ${showChannels && 'shown'}`}>
            <div className="user-info">
              {currentUser && <MemberList members={[currentUser]} selfText="" />}
              <button
                className="mobile material-icons-outlined"
                onClick={() => setShowChannels(false)}
              >
                close
              </button>
            </div>

            <div className="filter-input flex">
              <input
                onChange={(e) => setChannelsFilter(e.target.value)}
                placeholder="Cerca..."
                type="text"
                value={channelsFilter}
              />
              <img src={icnSearch} alt="search" className="-ml-8" />
            </div>

            <div className="channel-lists">
              <div>
                <ChannelList
                  channelRenderer={(channel) => channelRender({
                    channel,
                    switchChannel: (channel) => setChannel(channel),
                    currentChannel: currentChannel.id,
                    unreadCount: unreadChannelMessages[channel.id]
                  })}
                  channels={groupChannels}
                  sort={(a,b) => {
                    const priorityComparison = (b.custom?.priority ?? 0) - (a.custom?.priority ?? 0);
                    if (priorityComparison !== 0) {
                      return priorityComparison;
                    }
                    return (unreadChannelMessages[b.id] ?? 0) - (unreadChannelMessages[a.id] ?? 0);
                  }}
                />
              </div>
            </div>
          </div>

          <div className="chat-window">
            {currentChannel.id === 'default' && (
              <div className="channel-info">
                <span>
                  <div className="flex flex-col">
                    <p className="!mb-1 !text-gray-300">
                      <strong>Seleziona un cliente per chattare</strong>
                    </p>
                  </div>
                </span>
              </div>
            )}

            {currentChannel.id != 'default' && (
              <>
                <div className="channel-info">
                  <button
                    className="mobile material-icons-outlined"
                    onClick={() => setShowChannels(true)}
                  >
                    menu
                  </button>
                  <span onClick={() => setShowMembers(!showMembers)}>
                    <div className="flex gap-2">
                      <strong>{currentChannel.name || currentChannel.id}</strong>
                      <img src={icnArrowRight} alt="open" />
                    </div>
                  </span>
                  <hr />
                </div>

                <button className="my-button" onClick={() => setMessageUnread(currentChannel)}>
                  Imposta come non letto
                </button>

                <MessageList fetchMessages={20} messageRenderer={props => messageRenderer(props, deleteMessage, is_supervisor)} />
                <TypingIndicator />
                <hr />
                <MessageInput
                  typingIndicator
                  placeholder="Invia un messaggio"
                  onBeforeSend={(message) => ({
                    ...message,
                    pn_gcm: {
                      notification:{
                        title: "Nuovo messaggio",
                        body: message.text.substring(0,50),
                        sound: "default"
                      }
                    }
                  })}
                  onSend={(message) => {
                    console.log('onSend called', message);
                    setMessageRead(currentChannel);
                    setUnreadChannelMessage(prevState => ({ ...prevState, [currentChannel.id]: 0 }));
                    // set channel custom field lastMessageTimestamp
                    // pubnub.objects.setChannelMetadata({
                    //   channel: currentChannel.id,
                    //   data: {
                    //     custom: { lastMessageTimestamp: null }
                    //   }
                    // });
                  }}
                />
              </>
            )}
          </div>

          <div className={`members-panel ${showMembers ? 'shown' : 'hidden'}`}>
            <div className="flex items-center justify-between uppercase mt-10 text-base">
              <h3 className="ml-5">Partecipanti</h3>
              <button onClick={() => setShowMembers(false)} className="mr-4">
                <img src={icnClose} alt="close" />
              </button>
            </div>
            <MemberList
              members={channelMembers.filter((c) =>
                c.name?.toLowerCase().includes(membersFilter.toLowerCase())
              )}
            />
          </div>
        </Fragment>
      </Chat>
    </div>
  );
}
